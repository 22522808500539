<template lang="pug">
  div
    base-snackbar(
      :errors="billingGetErrors"
    )
    v-overlay(
      :value="billingGetting"
      absolute
    )
      v-progress-circular(
        indeterminate
        size="50"
        color="primary"
      )
    v-alert(
      type="success"
      v-if="completed"
    )
      span Completed
    v-row
      v-col(offset="11" cols="1")
        v-btn(
          color="yellow darken-3"
          block
          dark
          @click="printBill"
        )
          span PRINT
      v-col(cols="3")
        v-row
          v-col(cols="12")
            v-card
              v-card-subtitle.primary--text.text-uppercase Company Name
              v-card-text {{ (billingData.customer || {}).company }}
          v-col(cols="12")
            v-card
              v-card-subtitle.primary--text.text-uppercase Contact Name
              v-card-text {{ billingData.contact_name }}
          v-col(cols="12")
            v-card
              v-card-subtitle.primary--text.text-uppercase Phone #
              v-card-text {{ billingData.contact_number }}
          v-col(cols="12")
            v-card
              v-card-subtitle.primary--text.text-uppercase Fax #
              v-card-text {{ billingData.fax_number }}
          v-col(cols="12")
            v-card
              v-card-subtitle.primary--text.text-uppercase Email
              v-card-text {{ billingData.email }}
      v-col(cols="9")
          v-simple-table(dense)
            template(v-slot:default)
              thead
                tr
                  th Item
                  th Invoice No
                  th Date
                  th Particulars
                  th Status
                  th.text-right Amount
              tbody
                tr(v-for="(detail, index) in billingData.details" :key="detail.id")
                  td {{ index + 1 }}
                  td {{ drFormat(detail.invoice_id) }}
                  td {{ (detail.date || '').dateFormat() }}
                  td {{ detail.particulars }}
                  td(:class="statusColorCode(detail) + '--text'") {{ detail.status }}
                  td.text-right {{ (detail.amount || 0).phpFormat() }}
                tr
                  th(colspan="4") Total
                  th.text-right {{ (totalAmount || 0).phpFormat() }}
</template>
<script>
import billingRepository from '@/repositories/billing.repository'
import { requestVars } from '@/libs/api-helper.extra'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import hericaBillingPdf from '@/pdfs/herica-billing.pdf'

const [billingGetVars, billingGetVarNames] = requestVars({ identifier: 'billing', data: 'billingData' })
const billingGetHandler = new VueRequestHandler(null, billingGetVarNames)

export default {
  name: 'ShowBilling',
  data () {
    return {
      ...billingGetVars,
    }
  },
  created () {
    this.getBilling()
  },
  computed: {
    totalAmount () {
      return this.billingData.details.reduce((total, item) => total + item.amount, 0)
    },
    completed () {
      const detailsLength = this.billingData.details.length
      const completedLength = this.billingData.details.filter(item => item.order_status_id === 'CP').length
      return completedLength === detailsLength
    },
  },
  methods: {
    getBilling () {
      const { billingId } = this.$route.params
      const handler = billingGetHandler
      const repository = billingRepository.show
      handler.setVue(this)
      handler.execute(repository, [billingId])
    },
    drFormat (invoice) {
      return `DR${invoice.toString().padStart(6, '0')}`
    },
    printBill () {
      const item = this.billingData
      const billingFor = {
        companyName: item.customer.company,
        contactName: item.contact_name,
        phone: item.contact_number || '',
        email: item.email || '',
        billingNo: this.getBillingNumber(item.id),
        date: (item.created_at || '').dateFormat(),
      }
      const billingStatement = {
        items: this.getBillingStatementItems(item),
        totalAmount: this.getBillingStatementItemsTotalAmount(item),
      }
      hericaBillingPdf.generate(billingFor, billingStatement)
    },
    getBillingStatementItemsTotalAmount (billing) {
      return billing.details.reduce((total, item) => total + item.amount, 0).moneyFormat()
    },
    getBillingStatementItems (billing) {
      const minLength = 15
      const list = billing.details.map((subItem, index) => ({
        item: index + 1,
        invoiceNo: this.drFormat(subItem.invoice_id),
        date: (subItem.date || '').dateFormat(),
        particulars: subItem.particulars,
        amount: (subItem.amount || 0).moneyFormat(),
      }))
      if (minLength <= list.length) return list
      return list.concat(Array(minLength - list.length).fill({}))
    },
    getBillingNumber (id) {
      return `BL${id.toString().padStart(6, '0')}`
    },
    statusColorCode (data) {
      const statusCode = data.order_status_id
      if (statusCode === 'PL') return 'info'
      if (statusCode === 'CP') return 'success'
      if (statusCode === 'CL') return 'error'
      return 'primary'
    },
  },
}
</script>